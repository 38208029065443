import React, { Fragment, FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { PageTitle } from '../../components/PageTitle';
import { Overlay } from '../../components/Overlay';
import { Cell } from '../../components/Cell';
import { Image, ImageContainer, LegalText } from './styles';

interface Props {
  selectedImage: File;
  handleSubmitImage: () => void;
  handleClearImage: () => void;
  uploadingImage?: boolean;
}
export const ImageSelected: FC<Props> = ({
  selectedImage,
  handleSubmitImage,
  handleClearImage,
  uploadingImage
}) => {
  const imageUrl = URL.createObjectURL(selectedImage);
  return (
    <Fragment>
      {uploadingImage && (
        <Overlay message="Even geduld, je foto wordt verstuurd..." />
      )}
      <PageTitle>Dit is je foto</PageTitle>
      <ImageContainer>
        <Image src={imageUrl} alt="Dit is je foto" />
      </ImageContainer>
      <Cell>
        <Button onClick={handleSubmitImage}>Gebruik foto</Button>
      </Cell>
      <Cell>
        <Button
          variant="secondary"
          onClick={handleClearImage}
          disabled={uploadingImage}
        >
          Annuleren
        </Button>
      </Cell>

      <LegalText>
        Door je foto te uploaden ga je akkoord met de{' '}
        <Link to="/privacy">Algemene voorwaarden</Link>
      </LegalText>
    </Fragment>
  );
};
