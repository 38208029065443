import { Timber } from '@timberio/browser';

// temporarily disable logger
const loggerEnabled = false

const mode = process.env.NODE_ENV;

export let logger;

const addContext = async log => {
  return {
    ...log,
    source: 'timpel-site'
  };
};

if (mode !== 'development' && loggerEnabled) {
  logger = new Timber(
    process.env.REACT_APP_TIMBER_API_KEY,
    process.env.REACT_APP_TIMBER_SOURCE_ID,
    {
      ignoreExceptions: true
    }
  );
  logger.use(addContext);
} else {
  logger = console;
}
