import React from 'react';

export const Checkmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 16 12">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-386.000000, -695.000000)" fill="currentColor">
        <g transform="translate(386.000000, 695.000000)">
          <g>
            <polygon
              fillRule="nonzero"
              points="6.15384615 12 0 5.6412 1.72184615 3.9258 6.12123077 8.4096 14.2098462 -1.77635684e-15 16 1.6836"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
