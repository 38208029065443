import React, { Fragment, FC } from 'react';
import { Link } from 'react-router-dom';
import { Player, BigPlayButton } from 'video-react';
import { PageTitle } from '../../components/PageTitle';
import { FileUpload } from '../../components/FileUpload';
import { MetaDataBlock } from '../../components/MetaDataBlock';
import { Overlay } from '../../components/Overlay';
import { ProjectDescription } from '../../components/ProjectDescription';
import { Project } from '../../types/project';
import { MetaDataBlocks, InfoBlock, VideoWrapper, NoVideo } from './styles';
import { getDeviceType } from '../../utils/device';

interface Props {
  project?: Project;
  handleImageSelect: (file: File) => void;
  projectIsLoading?: boolean;
  projectNotFound?: boolean;
  projectHasError?: boolean;
}
export const ProjectDetails: FC<Props> = ({
  project,
  handleImageSelect,
  projectIsLoading,
  projectNotFound,
  projectHasError
}) => {
  const isMobile = getDeviceType() === 'mobile';
  return (
    <Fragment>
      {projectIsLoading && <Overlay message="Project wordt opgehaald..." />}
      {project && project.attributes ? (
        <Fragment>
          <PageTitle>{project.attributes.name}</PageTitle>
          {project.attributes.timelapseUrl ? (
            <VideoWrapper>
              <Player src={project.attributes.timelapseUrl}>
                <BigPlayButton position="center" />
              </Player>
            </VideoWrapper>
          ) : (
            <NoVideo>
              <p>Van dit project zijn nog geen foto's gemaakt</p>
            </NoVideo>
          )}
          {isMobile ? (
            <FileUpload onImageSelect={handleImageSelect} />
          ) : (
            <InfoBlock>
              Foto's uploaden is alleen mogelijk als je deze site met een smartphone of tablet
              bezoekt
            </InfoBlock>
          )}
          <MetaDataBlocks>
            {project.attributes.owner && (
              <MetaDataBlock
                type="Dit is een project van:"
                title={project.attributes.owner.name}
                imgUrl={project.attributes.owner.logo?.url}
              />
            )}
          </MetaDataBlocks>
          <ProjectDescription description={project.attributes.description} />
          {project.attributes.relatedTo && project.attributes.relatedTo.length > 0 && (
            <>
              <h4>Gerelateerde projecten</h4>
              {project.attributes.relatedTo.map((project) => (
                <Link key={project.id} to={`/${project.attributes.slug}`}>
                  {project.attributes.name}
                </Link>
              ))}
            </>
          )}
        </Fragment>
      ) : (
        'how to fix this?'
      )}
      {projectNotFound && <PageTitle>Project niet gevonden</PageTitle>}
      {projectHasError && <PageTitle>Er gaat iets fout bij het ophalen van het project</PageTitle>}
    </Fragment>
  );
};
