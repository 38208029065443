import React from 'react';

export const MenuIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 16 14">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M0,0 L16,0 L16,2 L0,2 L0,0 Z M0,6 L16,6 L16,8 L0,8 L0,6 Z M0,12 L16,12 L16,14 L0,14 L0,12 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
