import React, { ChangeEventHandler, FC } from 'react';
import {
  CheckboxInner,
  CheckboxWrapper,
  StyledCheckbox,
  Container,
  Label
} from './styles';

export interface CheckboxProps {
  label?: string;
  onChange?: ChangeEventHandler;
  value?: boolean;
  disabled?: boolean;
  verticalAlignment?: 'flex-start' | 'flex-end' | 'center';
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  onChange,
  value,
  disabled,
  verticalAlignment = 'center'
}: CheckboxProps & { children?: React.ReactNode }) => {
  return (
    <Container verticalAlignment={verticalAlignment}>
      <CheckboxWrapper disabled={disabled}>
        <StyledCheckbox
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
        <CheckboxInner />
      </CheckboxWrapper>
      <Label>{label}</Label>
    </Container>
  );
};

export default Checkbox;
