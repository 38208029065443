import React, {
  FC,
  Fragment,
  useReducer,
  Reducer,
  useState,
  useEffect
} from 'react';
import { useCookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { PageTitle } from '../../components/PageTitle';
import {
  reducer,
  initState,
  AuthenticateState
} from '../../reducers/authenticate';
import { AuthenticateActions } from '../../actions/authenticate';
import { login } from '../../requests/authenticate';
import { InputContainer } from './styles';

interface Props {
  match?: {
    params: {
      r: string;
    };
  };
}

export const Login: FC<RouteComponentProps & Props> = ({ match, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies();
  const [state, dispatch] = useReducer<
    Reducer<AuthenticateState, AuthenticateActions>
  >(reducer, initState);
  useEffect(() => {
    const redirectTo = `/${match.params.r}`;
    if (state.authenticated && state.auth) {
      setCookie('jwt_auth', state.auth.jwt, {
        path: '/',
        expires: moment()
          .add(7, 'd')
          .toDate()
      });
      history.push(redirectTo, { isAuthenticated: true });
    }
  }, [state.authenticated, state.auth, history, setCookie, match]);
  return (
    <Fragment>
      <PageTitle>Inloggen</PageTitle>
      <InputContainer>
        <Input
          type="email"
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
        />
      </InputContainer>
      <InputContainer>
        <Input
          type="password"
          value={password}
          onChange={e => setPassword(e.currentTarget.value)}
        />
      </InputContainer>
      <Button onClick={() => login(dispatch, email, password)}>Inloggen</Button>
    </Fragment>
  );
};
