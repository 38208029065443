import React, { useState, FC } from 'react';
import logo from './logo.png';
import { Icon } from '../Icons';
import { Menu } from '../Menu';
import { HeaderContainer, LogoContainer, MenuIcon, LogoImage } from './styles';

export const Header: FC = () => {
  const [showMenu, toggleMenu] = useState(false);
  return (
    <HeaderContainer>
      <LogoContainer>
        <LogoImage src={logo} alt="Timpel" />
      </LogoContainer>
      <MenuIcon onClick={() => toggleMenu(true)}>
        <Icon type="menu" />
      </MenuIcon>
      <Menu visible={showMenu} onCloseMenu={() => toggleMenu(false)} />
    </HeaderContainer>
  );
};
