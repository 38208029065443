import { AuthenticateActions } from '../actions/authenticate';
import { ActionTypes } from '../actions/actionTypes';
import { api } from '../utils/api';
import { logger } from '../utils/logger';

export const login = async (
  dispatch: React.Dispatch<AuthenticateActions>,
  emailAddress: string,
  password: string
) => {
  dispatch({ type: ActionTypes.LOGIN });
  const response = await api({
    path: `auth/local`,
    method: 'POST',
    body: {
      identifier: emailAddress,
      password: password
    }
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.LOGIN_ERROR });
    logger.error('Something went wrong trying to login.');
  } else {
    dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: response.data });
  }
};
