import React, { Fragment, FC } from 'react';
import showdown from 'showdown';
import { Description } from './styles';

const converter = new showdown.Converter();

interface Props {
  description: string;
}
export const ProjectDescription: FC<Props> = ({ description }) => {
  return (
    <Fragment>
      <h3>Over dit project</h3>
      <Description
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(description) }}
      />
    </Fragment>
  );
};
