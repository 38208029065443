import styled from 'styled-components';
import { animated } from 'react-spring';

export const Projects = styled.div`
  margin: 2rem 0;
`;

export const ProjectToggle = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const Thumbnails = styled(animated.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 2rem;
`;

export const ThumbContainer = styled.div`
  position: relative;
  flex: 0 1 auto;
  width: 110px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  :hover {
    cursor: pointer;
  }
`;

export const RemoveIcon = styled.div`
  width: 2rem;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  color: #4a4965;
  &:hover {
    color: #c95150;
    cursor: pointer;
  }
`;

export const RedDot = styled.div`
  min-width: 1.5rem;
  height: 1.5rem;
  padding: 0 2px;
  border-radius: 1rem;
  background-color: #c95150;
  color: white;
  line-height: 1.5rem;
  display: inline-block;
  text-align: center;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
`;

export const ProjectName = styled.div<{ active: boolean }>`
  text-align: left;
  font-size: 1.125rem;
  position: relative;
  padding: 1rem 0;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`;

export const CheckboxContainer = styled.span`
  position: absolute;
  bottom: 0.75rem;
  left: 0.5rem;
  z-index: 10;
`;

export const DeleteButtonContainer = styled.div`
  position: absolute;
  bottom: 0.75rem;
  right: 0.5rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
`;

export const Thumbnail = styled.img<{ enabled?: boolean }>`
  width: 100%;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.25)};
`;

export const Meta = styled.div<{ enabled?: boolean }>`
  background-color: #000;
  color: white;
  font-size: 0.625rem;
  position: absolute;
  top: 0;
  opacity: ${({ enabled }) => (enabled ? 0.75 : 0.25)};
  padding: 0 0.25rem;
`;

export const ProjectContainer = styled.div`
  border-bottom: 1px solid #979797;
  &:first-of-type {
    border-top: 1px solid #979797;
  }
`;

export const ThumbnailsContainer = styled.div`
  padding-bottom: 2rem;
`;

export const Toggle = styled.div`
  position: absolute;
  right: 0;
  top: 1rem;
  width: 1rem;
`;

// enabled status has green color
export const EnabledStatus = styled.div<{ enabled?: boolean }>`
  height: 1rem;
  color: ${({ enabled }) => (enabled ? '#00b894' : '#c95150')};
  font-size: 0.875rem;
  margin-right: 2rem;
`;
