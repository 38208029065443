import { Projects } from '../types/project';
import { ActionTypes } from '../actions/actionTypes';
import { ProjectsActions } from '../actions/projects';

export interface ProjectsState {
  projects?: Projects;
  isLoading: boolean;
  hasError: boolean;
  newPhotosLoaded: boolean;
  allPhotosLoaded: boolean;
  isLoadingPhotos: boolean;
  isBulkUpdating: boolean;
}

export const reducer: React.Reducer<ProjectsState, ProjectsActions> = (state, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECTS:
      return { ...state, isLoading: true };
    case ActionTypes.FETCH_PROJECTS_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case ActionTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        projects: action.payload
      };

    case ActionTypes.FETCH_NEW_PHOTOS_FOR_PROJECT:
    case ActionTypes.FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT:
    case ActionTypes.FETCH_REJECTED_PHOTOS_FOR_PROJECT:
      return {
        ...state,
        isLoadingPhotos: true
      };
    case ActionTypes.FETCH_NEW_PHOTOS_FOR_PROJECT_ERROR:
    case ActionTypes.FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT_ERROR:
    case ActionTypes.FETCH_REJECTED_PHOTOS_FOR_PROJECT_ERROR:
      return {
        ...state,
        isLoadingPhotos: false,
        hasError: true,
        newPhotosLoaded: true
      };
    case ActionTypes.FETCH_NEW_PHOTOS_FOR_PROJECT_SUCCESS:
      const newPhotosProject = state.projects?.find(
        (project) => project.attributes.slug === action.payload.projectSlug
      );
      return {
        ...state,
        isLoadingPhotos: false,
        hasError: false,
        newPhotosLoaded: true,
        projects: [
          ...(state.projects
            ? state.projects.filter(
                (project) => project.attributes.slug !== action.payload.projectSlug
              )
            : []),
          ...(newPhotosProject
            ? [
                {
                  ...newPhotosProject,
                  newPhotos: action.payload.data.map((photo) => ({
                    ...photo,
                    isSelected: true
                  }))
                }
              ]
            : [])
        ].sort((a, b) => (a.attributes.name > b.attributes.name ? 1 : -1))
      };
    case ActionTypes.FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT_SUCCESS:
      const timelapsePhotosProject = state.projects?.find(
        (project) => project.attributes.slug === action.payload.projectSlug
      );
      return {
        ...state,
        isLoadingPhotos: false,
        hasError: false,
        newPhotosLoaded: true,
        projects: [
          ...(state.projects
            ? state.projects.filter(
                (project) => project.attributes.slug !== action.payload.projectSlug
              )
            : []),
          ...(timelapsePhotosProject
            ? [
                {
                  ...timelapsePhotosProject,
                  timelapsePhotos: action.payload.data.map((photo) => ({
                    ...photo,
                    isSelected: true
                  }))
                }
              ]
            : [])
        ].sort((a, b) => (a.attributes.name > b.attributes.name ? 1 : -1))
      };
    case ActionTypes.FETCH_REJECTED_PHOTOS_FOR_PROJECT_SUCCESS:
      const rejectedPhotosProject = state.projects?.find(
        (project) => project.attributes.slug === action.payload.projectSlug
      );
      return {
        ...state,
        isLoadingPhotos: false,
        hasError: false,
        newPhotosLoaded: true,
        projects: [
          ...(state.projects
            ? state.projects.filter(
                (project) => project.attributes.slug !== action.payload.projectSlug
              )
            : []),
          ...(rejectedPhotosProject
            ? [
                {
                  ...rejectedPhotosProject,
                  rejectedPhotos: action.payload.data.map((photo) => ({
                    ...photo,
                    isSelected: false
                  }))
                }
              ]
            : [])
        ].sort((a, b) => (a.attributes.name > b.attributes.name ? 1 : -1))
      };
    case ActionTypes.FETCH_ALL_PHOTOS:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.FETCH_ALL_PHOTOS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        allPhotosLoaded: true
      };
    case ActionTypes.FETCH_ALL_PHOTOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        allPhotosLoaded: true,
        projects:
          state.projects &&
          state.projects.map((project) => ({
            ...project,
            tielapse: action.payload.data
              .filter(
                (photo) => photo.attributes.project.data.attributes.slug === project.attributes.slug
              )
              .map((photo) => ({ ...photo, isSelected: true }))
          }))
      };
    case ActionTypes.DELETE_PHOTO:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.DELETE_PHOTO_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case ActionTypes.DELETE_PHOTO_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case ActionTypes.TOGGLE_SELECTED_PHOTO:
      const photosType =
        action.payload.type === 'NEW'
          ? 'newPhotos'
          : action.payload.type === 'TIMELAPSE'
          ? 'timelapsePhotos'
          : 'rejectedPhotos';
      return {
        ...state,
        projects:
          state.projects &&
          state.projects.map((project) => ({
            ...project,
            [photosType]:
              project.id === action.payload.projectId
                ? project[photosType] &&
                  project[photosType]!.map((photo) =>
                    photo.id === action.payload.photoId
                      ? {
                          ...photo,
                          isSelected: !photo.isSelected
                        }
                      : photo
                  )
                : project[photosType]
          }))
      };
    case ActionTypes.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        newPhotosLoaded: false
      };

    case ActionTypes.BULK_UPDATE_PHOTOS:
      return {
        ...state,
        isBulkUpdating: true
      };

    case ActionTypes.BULK_UPDATE_PHOTOS_SUCCESS:
    case ActionTypes.BULK_UPDATE_PHOTOS_ERROR:
      return {
        ...state,
        isBulkUpdating: false
      };

    default:
      return state;
  }
};

export const initState: ProjectsState = {
  isLoading: false,
  isLoadingPhotos: false,
  isBulkUpdating: false,
  hasError: false,
  newPhotosLoaded: false,
  allPhotosLoaded: false
};
