import React, { FC } from 'react';
import { Icon } from '../Icons';
import { StyledOverlay, SpinnerWrapper, Message } from './styles';

interface Props {
  message?: string;
}
export const Overlay: FC<Props> = ({ message }) => (
  <StyledOverlay>
    <SpinnerWrapper>
      <Icon type="spinner" />
    </SpinnerWrapper>
    {message && <Message>{message}</Message>}
  </StyledOverlay>
);
