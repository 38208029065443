import React, { useState, FC } from 'react';
import { PageTitle } from '../../components/PageTitle';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { ProjectDescription } from '../../components/ProjectDescription';
import { InputWrapper, Hide } from './styles';

interface Props {
  storeEmailAddress: (email: string) => void;
  storingEmailAddress?: boolean;
  storeEmailAddressError?: boolean;
  storeEmailAddressSuccess?: boolean;
  projectDescription?: string;
}
export const ThankYouPage: FC<Props> = ({
  storeEmailAddress,
  storingEmailAddress,
  storeEmailAddressError,
  storeEmailAddressSuccess,
  projectDescription
}) => {
  const [email, setEmail] = useState('');
  return (
    <>
      <PageTitle>Bedankt!</PageTitle>
      <p>
        Als we voldoende foto's hebben verzameld kun je hier de timelapse met
        jouw foto bekijken.
      </p>
      <Hide>
        <p>Wil je op de hoogte blijven?</p>
        <p>Vul dan hieronder je e-mail adres in.</p>
        {storeEmailAddressSuccess ? (
          <h3>Top, je hoort snel van ons! Echt waar!</h3>
        ) : (
          <>
            <InputWrapper>
              <Input
                type="email"
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
              />
            </InputWrapper>
            <Button
              type="submit"
              onClick={() => storeEmailAddress(email)}
              disabled={storeEmailAddressSuccess}
            >
              Verstuur
            </Button>
          </>
        )}
      </Hide>
      {projectDescription && (
        <ProjectDescription description={projectDescription} />
      )}
    </>
  );
};
