import React, { FC } from 'react';
import { Block, Image, ImageContainer } from './styles';

interface Props {
  type: string;
  title: string;
  imgUrl: string;
}

export const MetaDataBlock: FC<Props> = ({ type, title, imgUrl }) => (
  <Block>
    <h4>{type}</h4>
    <ImageContainer>
      <Image src={imgUrl} alt={title} />
    </ImageContainer>
  </Block>
);
