import React from 'react';

export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-961.000000, -687.000000)" fill="currentColor">
        <g transform="translate(961.000000, 687.000000)">
          <polygon
            id="Shape"
            points="7.65217391 7.65217391 7.65217391 0 8.34782609 0 8.34782609 7.65217391 16 7.65217391 16 8.34782609 8.34782609 8.34782609 8.34782609 16 7.65217391 16 7.65217391 8.34782609 0 8.34782609 0 7.65217391"
          />
        </g>
      </g>
    </g>
  </svg>
);
