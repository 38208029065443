import styled from 'styled-components';

export interface ContainerProps {
  verticalAlignment: 'flex-start' | 'flex-end' | 'center';
}

export const Container = styled.label<ContainerProps>`
  display: flex;
  align-items: ${props => props.verticalAlignment};
`;

export const Label = styled.div`
  display: inline-block;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
`;

export const CheckboxInner = styled.span`
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: 2px solid #84bdab;
  background-color: #fff;
  transition: all 0.2s;
  margin-right: 1rem;
  ${StyledCheckbox}:checked + & {
    background-color: #84bdab;
    border-color: #84bdab;
  }
  ${StyledCheckbox}:disabled + & {
    background-color: #aaa;
    border-color: #aaa;
    cursor: auto;
  }

  &::after {
    position: absolute;
    content: '';
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    ${StyledCheckbox}:checked + & {
      left: 0.5rem;
      top: 2px;
      width: 0.5rem;
      height: 1rem;
      transform: rotate(45deg);
      border-style: solid;
      border-color: white;
      border-image: initial;
      border-width: 0 3px 3px 0;
      opacity: 1;
    }
    ${StyledCheckbox}:checked:disabled + & {
      border-color: #666;
    }
  }
`;

export const CheckboxWrapper = styled.span<{ disabled?: boolean }>`
  margin: 0;
  outline: none;
  line-height: 1;
  vertical-align: text-bottom;
  padding: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
`;
