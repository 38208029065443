import styled from 'styled-components';

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 4em;
  left: calc(50% - 2em);
  width: 4em;
  height: 4em;
  color: #84bdab;
`;

export const Message = styled.div`
  margin-top: 8rem;
  padding: 0 2rem;
  text-align: center;
  font-size: 1rem;
  color: #8aa5b6;
  background-color: #fff;
  box-shadow: 0px 0px 50px 50px #fff;
`;
