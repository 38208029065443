import styled from 'styled-components';

export const Content = styled.div`
  img {
    max-width: 100%;
  }
`;

export const VideoWrapper = styled.div`
  left: 0;
  right: 0;
  margin: 2rem 0;
`;

export const PageWrapper = styled.div<{ videoOnTop: boolean }>`
  ${({ videoOnTop }) => videoOnTop && `margin-top: 32rem;`};
`;

export const SubTitle = styled.div`
  background-color: #4a4965;
  color: white;
  padding: 1rem 0;
  font-size: 0.875rem;
`;
