import { api } from '../utils/api';
import { logger } from '../utils/logger';
import { TimelapseActions } from '../actions/timelapse';
import { ActionTypes } from '../actions/actionTypes';
import { TransloaditAssembly } from '../types/timelapse';

export const createTimelapse = async (
  dispatch: React.Dispatch<TimelapseActions>,
  options: TransloaditAssembly
) => {
  dispatch({ type: ActionTypes.CREATE_TIMELAPSE, payload: options });
  const response = await api({
    path: `assemblies`,
    method: 'POST',
    body: options,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.CREATE_TIMELAPSE_ERROR });
    logger.error(
      `Creating timelapse failed! Error: ${response.status}, ${response.error}`
    );
  } else {
    dispatch({
      type: ActionTypes.CREATE_TIMELAPSE_SUCCESS,
      payload: JSON.parse(response.data)
    });
    logger.info(
      `Timelapse Assembly with ID '${
        JSON.parse(response.data).assembly_id
      }' succesfully started`
    );
  }
};

export const fetchAssemblyStatus = async (
  dispatch: React.Dispatch<TimelapseActions>,
  assemblyId: string
) => {
  const response = await api({
    path: `assemblies/${assemblyId}`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error) {
    logger.error(
      `Retrieving assembly status failed: ${response.status}, ${response.error}`
    );
  } else {
    dispatch({
      type: ActionTypes.FETCH_ASSEMBLY_STATUS_SUCCESS,
      payload: response.data
    });
  }
};
