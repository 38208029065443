import React, { FC, Fragment, useReducer, Reducer, useEffect, useState } from 'react';
import { ProjectPhotos } from './ProjectPhotos';
import { PageTitle } from '../../components/PageTitle';
import { Overlay } from '../../components/Overlay';

import {
  TimelapseState,
  reducer as timelapseReducer,
  initState as timelapseInitState
} from '../../reducers/timelapse';
import {
  ProjectsState,
  reducer as projectsReducer,
  initState as projectsInitState
} from '../../reducers/projects';

import { ActionTypes } from '../../actions/actionTypes';
import { TimelapseActions } from '../../actions/timelapse';
import { ProjectsActions } from '../../actions/projects';

import { createTimelapse, fetchAssemblyStatus } from '../../requests/timelapse';
import { fetchAllProjects, updateProject } from '../../requests/projects';
import {
  fetchTimelapsePhotos,
  getNewPhotosForProject,
  getTimelapsePhotosForProject,
  getRejectedPhotosForProject,
  bulkUpdatePhotos,
  bulkDeletePhotos
} from '../../requests/photo';

import { Project } from '../../types/project';
import { PhotoType } from '../../types/photo';

import { transloaditStatus, transloaditOptions } from '../../utils/transloadit';
import { Projects } from './styles';

export const Admin: FC<{ userName: string }> = ({ userName }) => {
  const [activeProject, setActiveProject] = useState<string>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [timelapse, dispatchTimelapse] = useReducer<Reducer<TimelapseState, TimelapseActions>>(
    timelapseReducer,
    timelapseInitState
  );
  const [projects, dispatchProjects] = useReducer<Reducer<ProjectsState, ProjectsActions>>(
    projectsReducer,
    projectsInitState
  );

  // functions
  const togglePhoto = (projectId: string, photoId: string, type: PhotoType) => {
    dispatchProjects({
      type: ActionTypes.TOGGLE_SELECTED_PHOTO,
      payload: { projectId, photoId, type }
    });
  };

  const getPhotosForTimelapse = async (
    { attributes: { slug }, newPhotos, timelapsePhotos, id }: Project,
    type: PhotoType
  ) => {
    const photosToProcess = type === 'NEW' ? newPhotos : timelapsePhotos;
    if (photosToProcess && photosToProcess.length) {
      const photosForTimelapse = photosToProcess.filter((photo) => photo.isSelected);
      const photosNotForTimelapse = photosToProcess.filter((photo) => !photo.isSelected);
      dispatchProjects({
        type: ActionTypes.BULK_UPDATE_PHOTOS
      });
      await Promise.all([
        await bulkUpdatePhotos({
          photoIds: photosForTimelapse.map((photo) => photo.id),
          field: 'timelapse',
          value: true
        }),
        await bulkUpdatePhotos({
          photoIds: photosNotForTimelapse.map((photo) => photo.id),
          field: 'timelapse',
          value: false
        }),
        await bulkUpdatePhotos({
          photoIds: photosToProcess.map((photo) => photo.id),
          field: 'new',
          value: false
        })
      ]);
      dispatchProjects({
        type: ActionTypes.BULK_UPDATE_PHOTOS_SUCCESS
      });
      if (photosForTimelapse.length === 0) {
        fetchAllProjects(dispatchProjects);
      } else {
        fetchTimelapsePhotos(dispatchTimelapse, id, slug);
      }
    }
  };

  const putBackPhotos = async ({ rejectedPhotos }: Project) => {
    if (rejectedPhotos?.length) {
      const photosToPutBack = rejectedPhotos.filter((photo) => photo.isSelected);
      dispatchProjects({
        type: ActionTypes.BULK_UPDATE_PHOTOS
      });
      await bulkUpdatePhotos({
        photoIds: photosToPutBack.map((photo) => photo.id),
        field: 'new',
        value: true
      });
      dispatchProjects({
        type: ActionTypes.BULK_UPDATE_PHOTOS_SUCCESS
      });
      fetchAllProjects(dispatchProjects);
      setActiveProject(undefined);
    }
  };

  const deletePhotos = async ({ rejectedPhotos }: Project) => {
    if (rejectedPhotos?.length) {
      const photosToDelete = rejectedPhotos.filter((photo) => photo.isSelected);
      dispatchProjects({
        type: ActionTypes.BULK_UPDATE_PHOTOS
      });
      await bulkDeletePhotos({
        photoIds: photosToDelete.map((photo) => photo.id)
      });
      dispatchProjects({
        type: ActionTypes.BULK_UPDATE_PHOTOS_SUCCESS
      });
      fetchAllProjects(dispatchProjects);
      setActiveProject(undefined);
    }
  };

  const onSetTab = async (tabIndex: number) => {
    setSelectedTab(tabIndex);
    if (tabIndex === 1 && activeProject) {
      // check if photos are already loaded
      if (projects.projects) {
        const project = projects.projects.find(
          (project) => project.attributes.slug === activeProject
        );
        if (
          project &&
          project.timelapsePhotosCount &&
          (!project.timelapsePhotos || project.timelapsePhotos.length === 0)
        ) {
          await getTimelapsePhotosForProject(dispatchProjects, activeProject);
        }
      }
    } else if (tabIndex === 2 && activeProject) {
      // get all rejected photos
      if (projects.projects) {
        const project = projects.projects.find(
          (project) => project.attributes.slug === activeProject
        );
        if (
          project &&
          project.rejectedPhotosCount &&
          (!project.rejectedPhotos || project.rejectedPhotos.length === 0)
        ) {
          await getRejectedPhotosForProject(dispatchProjects, activeProject);
        }
      }
    }
  };

  const onSetActiveProject = async (slug?: string) => {
    setActiveProject(slug);
    if (slug && projects.projects) {
      const project = projects.projects.find((project) => project.attributes.slug === slug);
      if (
        project &&
        project.newPhotosCount &&
        (!project.newPhotos || project.newPhotos.length === 0)
      ) {
        await getNewPhotosForProject(dispatchProjects, slug);
      }
    }
    setSelectedTab(0);
  };

  // effects
  useEffect(() => {
    fetchAllProjects(dispatchProjects);
  }, []);

  useEffect(() => {
    console.log(projects);
  }, [projects]);

  useEffect(() => {
    console.log(
      'trigger to create timelapse: ',
      timelapse.photosToProcess,
      timelapse.projectSlugToProcess
    );
    if (
      timelapse.photosToProcess &&
      timelapse.photosToProcess.length > 0 &&
      timelapse.projectSlugToProcess
    ) {
      createTimelapse(
        dispatchTimelapse,
        transloaditOptions(timelapse.projectSlugToProcess, timelapse.photosToProcess)
      );
    }
  }, [timelapse.photosToProcess, timelapse.projectSlugToProcess]);

  useEffect(() => {
    if (timelapse.assembly && timelapse.assembly.ok !== transloaditStatus.ASSEMBLY_COMPLETED) {
      const getStatus = setInterval(
        () => fetchAssemblyStatus(dispatchTimelapse, timelapse.assembly.assembly_id),
        5000
      );
      return () => clearInterval(getStatus);
    } else if (
      timelapse.assembly &&
      timelapse.assembly.ok === transloaditStatus.ASSEMBLY_COMPLETED
    ) {
      dispatchTimelapse({ type: ActionTypes.CREATE_TIMELAPSE_READY });
    } else {
      dispatchTimelapse({ type: ActionTypes.CREATE_TIMELAPSE_ERROR });
    }
  }, [timelapse.assembly]);

  useEffect(() => {
    if (
      timelapse.timelapseIsReady &&
      timelapse.projectIdToProcess &&
      timelapse.assembly &&
      timelapse.assembly.results &&
      timelapse.assembly.results.merged
    ) {
      updateProject(
        dispatchProjects,
        timelapse.projectIdToProcess,
        timelapse.assembly.results.merged[0].ssl_url
      );
      setActiveProject(undefined);
      setSelectedTab(0);
      fetchAllProjects(dispatchProjects);
    }
  }, [timelapse.timelapseIsReady, timelapse.projectIdToProcess, timelapse.assembly]);

  return (
    <Fragment>
      {projects.isBulkUpdating && <Overlay message="Even geduld, foto's worden verwerkt..." />}
      {timelapse.isLoading && (
        <Overlay message="Even geduld, de nieuwe timelapse wordt gemaakt, dit kan even duren..." />
      )}
      <Fragment>
        <PageTitle>Foto management</PageTitle>

        {projects.isLoading ? (
          <p>Even geduld, projecten worden geladen...</p>
        ) : (
          <>
            <p>
              Kies een project om de nieuwe foto’s te bekijken of om de huidige timelapse aan te
              passen
            </p>

            <Projects>
              {projects.projects &&
                projects.projects.map(
                  (project) =>
                    (process.env.NODE_ENV === 'development' || project.attributes.enabled) && (
                      <ProjectPhotos
                        key={project.id}
                        project={project}
                        togglePhoto={togglePhoto}
                        getPhotosForTimelapse={getPhotosForTimelapse}
                        putBackPhotos={putBackPhotos}
                        deletePhotos={deletePhotos}
                        isLoadingTimelapse={timelapse.isLoading}
                        isLoadingPhotos={projects.isLoadingPhotos}
                        activeProject={activeProject}
                        setActiveProject={onSetActiveProject}
                        selectedTab={selectedTab}
                        setSelectedTab={onSetTab}
                      />
                    )
                )}
            </Projects>
          </>
        )}
      </Fragment>
    </Fragment>
  );
};
