import React, { FC } from 'react';
import { Project } from '../../types/project';
import { Photo, PhotoType } from '../../types/photo';
import { ProjectThumbnails } from './ProjectThumbnails';
import { Tabs } from '../../components/Tabs';
import {
  RedDot,
  ProjectName,
  ProjectToggle,
  ProjectContainer,
  Toggle
  // EnabledStatus
} from './styles';
import { Icon } from '../../components/Icons';

const NEW: PhotoType = 'NEW';
const TIMELAPSE: PhotoType = 'TIMELAPSE';
const REJECTED: PhotoType = 'REJECTED';

interface Props {
  project: Project;
  togglePhoto: (projectId: string, photoId: string, type: PhotoType) => void;
  getPhotosForTimelapse: (project: Project, type: PhotoType) => void;
  putBackPhotos: (project: Project) => void;
  deletePhotos: (project: Project) => void;
  isLoadingTimelapse: boolean;
  isLoadingPhotos: boolean;
  activeProject?: string;
  setActiveProject: (slug?: string) => void;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
}

export const ProjectPhotos: FC<Props> = ({
  project,
  togglePhoto,
  getPhotosForTimelapse,
  putBackPhotos,
  deletePhotos,
  isLoadingTimelapse,
  isLoadingPhotos,
  activeProject,
  setActiveProject,
  selectedTab,
  setSelectedTab
}) => {
  const _renderThumbnails = (type: PhotoType, photos: Photo[]) => (
    <ProjectThumbnails
      project={project}
      onTogglePhoto={togglePhoto}
      onCreateTimelapse={() => getPhotosForTimelapse(project, type)}
      putBackPhotos={() => putBackPhotos(project)}
      deletePhotos={() => deletePhotos(project)}
      isLoadingTimelapse={isLoadingTimelapse}
      type={type}
      photos={photos}
    />
  );
  return (
    <ProjectContainer key={project.id}>
      <ProjectToggle
        onClick={() => {
          console.log('toggle project', project.attributes.slug);
          setActiveProject(
            activeProject !== project.attributes.slug ? project.attributes.slug : undefined
          );
          setSelectedTab(0);
        }}
      >
        <ProjectName active={project.attributes.slug === activeProject}>
          {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>{project.attributes.name}</div>
            {project.newPhotosCount > 0 && <RedDot>{project.newPhotosCount}</RedDot>}
            <EnabledStatus enabled={project.attributes.enabled}>
              {project.attributes.enabled ? 'Gepubliceerd' : 'Uitgezet'}
            </EnabledStatus>
          </div> */}
          {project.attributes.name}
          {project.newPhotosCount > 0 && <RedDot>{project.newPhotosCount}</RedDot>}
          <Toggle>
            {project.attributes.slug === activeProject ? (
              <Icon type="arrowUp" />
            ) : (
              <Icon type="arrowDown" />
            )}
          </Toggle>
        </ProjectName>
      </ProjectToggle>
      {project.attributes.slug === activeProject && (
        <Tabs
          selectedIndex={selectedTab}
          updateSelectedTabIndex={setSelectedTab}
          items={[
            {
              id: NEW,
              title: `Nieuwe foto's (${project.newPhotosCount})`,
              content: isLoadingPhotos ? (
                <p>Foto's worden geladen...</p>
              ) : project.newPhotos && project.newPhotos?.length > 0 ? (
                <>
                  <p>
                    Geef hieronder aan welke nieuwe foto's je wilt gebruiken en maak eventueel een
                    nieuwe timelapse
                  </p>
                  {_renderThumbnails(NEW, project.newPhotos)}
                </>
              ) : (
                <p>Er zijn voor dit project geen nieuwe foto's</p>
              )
            },
            {
              id: TIMELAPSE,
              title: `Timelapse foto's (${project.timelapsePhotosCount})`,
              content: isLoadingPhotos ? (
                <p>Foto's worden geladen...</p>
              ) : project.timelapsePhotos && project.timelapsePhotos.length > 0 ? (
                <>
                  <p>
                    Zet hieronder de foto's uit die je wilt verwijderen en maak een nieuwe timelapse
                  </p>
                  {_renderThumbnails(TIMELAPSE, project.timelapsePhotos)}
                </>
              ) : (
                <p>Er is voor dit project nog geen timelapse</p>
              )
            },
            {
              id: REJECTED,
              title: `Afgekeurde foto's (${project.rejectedPhotosCount})`,
              content: isLoadingPhotos ? (
                <p>Foto's worden geladen...</p>
              ) : project.rejectedPhotos && project.rejectedPhotos.length > 0 ? (
                <>
                  <p>
                    Selecteer de foto's die je definitief wilt verwijderen of terugzetten naar
                    Nieuwe foto's
                  </p>
                  {_renderThumbnails(REJECTED, project.rejectedPhotos)}
                </>
              ) : (
                <p>Er zijn voor dit project nog geen afgekeurde foto's</p>
              )
            }
          ]}
        />
      )}
    </ProjectContainer>
  );
};
