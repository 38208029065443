import styled from 'styled-components';

export const FileName = styled.div`
  margin: 1rem 0;
  color: white;
`;

export const FilePreview = styled.img`
  max-width: 60vw;
`;
