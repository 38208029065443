import { DateTime } from 'luxon';
import { ProjectsActions } from '../actions/projects';
import { TimelapseActions } from '../actions/timelapse';
import { ActionTypes } from '../actions/actionTypes';
import { ProjectActions } from '../actions/project';
import { api } from '../utils/api';
import { logger } from '../utils/logger';
import { Project } from '../types/project';
import { FetchPhotosResponse } from '../types/photo';

const PHOTO_PATH = 'photos';
const PROJECT_PATH = 'projects';

export const getNewPhotosForProject = async (
  dispatch: React.Dispatch<ProjectsActions>,
  projectSlug: string
): Promise<void> => {
  dispatch({ type: ActionTypes.FETCH_NEW_PHOTOS_FOR_PROJECT });
  const response = await api({
    path: `${PHOTO_PATH}?filters[project][slug][$eq]=${projectSlug}&filters[new][$eq]=true&sort=title:desc&pagination[limit]=-1&populate=file&populate=project`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.FETCH_NEW_PHOTOS_FOR_PROJECT_ERROR });
    logger.error(`No new photos found`);
  } else {
    dispatch({
      type: ActionTypes.FETCH_NEW_PHOTOS_FOR_PROJECT_SUCCESS,
      payload: { data: response.data.data, projectSlug: projectSlug }
    });
  }
};

export const getTimelapsePhotosForProject = async (
  dispatch: React.Dispatch<ProjectsActions>,
  projectSlug: string
): Promise<void> => {
  dispatch({ type: ActionTypes.FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT });
  const response = await api({
    path: `${PHOTO_PATH}?filters[project][slug][$eq]=${projectSlug}&filters[timelapse][$eq]=true&sort=title:desc&pagination[limit]=-1&populate=file&populate=project`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT_ERROR });
    logger.error(`No new photos found`);
  } else {
    dispatch({
      type: ActionTypes.FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT_SUCCESS,
      payload: { data: response.data.data, projectSlug: projectSlug }
    });
  }
};
export const getRejectedPhotosForProject = async (
  dispatch: React.Dispatch<ProjectsActions>,
  projectSlug: string
): Promise<void> => {
  dispatch({ type: ActionTypes.FETCH_REJECTED_PHOTOS_FOR_PROJECT });
  const response = await api({
    path: `${PHOTO_PATH}?filters[project][slug][$eq]=${projectSlug}&filters[timelapse][$eq]=false&filters[new][$eq]=false&sort=title:desc&pagination[limit]=-1&populate=file&populate=project`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.FETCH_REJECTED_PHOTOS_FOR_PROJECT_ERROR });
    logger.error(`No new photos found`);
  } else {
    dispatch({
      type: ActionTypes.FETCH_REJECTED_PHOTOS_FOR_PROJECT_SUCCESS,
      payload: { data: response.data.data, projectSlug: projectSlug }
    });
  }
};

export const storeImage = async (
  dispatch: React.Dispatch<ProjectActions>,
  project: Project,
  imageUrl: string,
  fileName: string,
  imageId: number
) => {
  dispatch({ type: ActionTypes.STORE_IMAGE, payload: imageUrl });
  const uploadedAt = DateTime.local();
  const response = await api({
    path: PHOTO_PATH,
    method: 'POST',
    body: {
      data: {
        title: fileName,
        project: {
          connect: [project.id]
        },
        new: true,
        timelapse: false,
        url: imageUrl,
        file: imageId,
        uploadedAt: uploadedAt.toISO()
      }
    }
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.STORE_IMAGE_ERROR });
  } else {
    dispatch({
      type: ActionTypes.STORE_IMAGE_SUCCESS,
      payload: response.data
    });
    // connect photo to project
    const photoId = response.data.data.id;
    const projectResponse = await api({
      path: `${PROJECT_PATH}/${project.id}`,
      method: 'PUT',
      body: {
        data: {
          photos: {
            connect: [photoId]
          }
        }
      }
    });
    if (projectResponse.status !== 200 || projectResponse.error) {
      logger.error(`No photo was connected to project`);
    } else {
      logger.info(`Photo was connected to project`);
    }
  }
};

export const bulkUpdatePhotos = async ({
  photoIds,
  field,
  value
}: {
  photoIds: string[];
  field: string;
  value: string | boolean;
}) => {
  const response = await api({
    path: `${PHOTO_PATH}/bulk-update`,
    method: 'PUT',
    body: {
      data: {
        ids: photoIds,
        data: {
          [field]: value
        }
      }
    }
  });
  if (response.status !== 200 || response.error) {
    logger.error(`Photos are not updated`);
  }
};

export const updatePhotoLabels = async ({
  id,
  isTimelapse = false,
  isNew = false
}: {
  id: string;
  isTimelapse?: boolean;
  isNew?: boolean;
}) => {
  const response = await api({
    path: `${PHOTO_PATH}/${id}`,
    method: 'PUT',
    body: {
      data: {
        new: isNew,
        timelapse: isTimelapse
      }
    }
  });
  if (response.status !== 200 || response.error) {
    logger.error(`Photo label is not updated`);
  }
};

export const bulkDeletePhotos = async ({ photoIds }: { photoIds: string[] }) => {
  const response = await api({
    path: `${PHOTO_PATH}/bulk-delete`,
    method: 'POST',
    body: {
      data: {
        ids: photoIds
      }
    }
  });
  if (response.status !== 200 || response.error) {
    logger.error(`Photos are not updated`);
  }
};

export const fetchTimelapsePhotos = async (
  dispatch: React.Dispatch<TimelapseActions>,
  projectId: string,
  projectSlug: string
) => {
  dispatch({ type: ActionTypes.FETCH_TIMELAPSE_PHOTOS });
  const response: FetchPhotosResponse = await api({
    path: `${PHOTO_PATH}?filters[project][slug][$eq]=${projectSlug}&filters[timelapse][$eq]=true&sort=title:desc&pagination[limit]=-1&populate=file`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error || !response.data) {
    dispatch({ type: ActionTypes.FETCH_TIMELAPSE_PHOTOS_ERROR });
    logger.error(`No timelapse photo's for project '${projectSlug}' were found`);
  } else {
    dispatch({
      type: ActionTypes.FETCH_TIMELAPSE_PHOTOS_SUCCESS,
      payload: {
        photos: response.data.data,
        projectSlug: projectSlug,
        projectId: projectId
      }
    });
  }
};
