import React, { FC } from 'react';
import { useSpring } from 'react-spring';
import { Icon } from '../Icons';
import {
  MenuWrapper,
  CloseButton,
  MenuItems,
  MenuItem,
  PageCover,
  MenuContainer
} from './styles';

const menuLinks = [
  {
    path: '/',
    title: 'Over Timpel'
  },
  {
    path: '/projects',
    title: 'Alle projecten'
  },
  {
    path: '/privacy',
    title: 'Privacy statement'
  },
  {
    path: '/contact',
    title: 'Contact'
  }
];

interface Props {
  onCloseMenu: () => void;
  visible: boolean;
}

export const Menu: FC<Props> = ({ onCloseMenu, visible }) => {
  return (
    <MenuWrapper>
      <PageCover
        style={useSpring({
          to: {
            display: visible ? 'block' : 'none',
            opacity: visible ? 1 : 0
          }
        })}
        onClick={onCloseMenu}
      />
      <MenuContainer
        style={useSpring({
          to: { right: visible ? 0 : -401 },
          config: { mass: 1, tension: 300, friction: 15, clamp: true }
        })}
        onClick={e => e.stopPropagation()}
      >
        <CloseButton onClick={onCloseMenu}>
          <Icon type="cross" />
        </CloseButton>
        <MenuItems>
          {menuLinks.map((link, index) => (
            <MenuItem key={index} to={link.path} onClick={onCloseMenu}>
              {link.title}
            </MenuItem>
          ))}
        </MenuItems>
      </MenuContainer>
    </MenuWrapper>
  );
};
