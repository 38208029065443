import styled, { css } from 'styled-components';

export type Span = number | 'grow';

const flexStyle = (span: Span) =>
  typeof span === 'number'
    ? `flex: 0 0 ${(100 * span) / 12}%`
    : span === 'grow'
      ? 'flex: 1'
      : '';

const writeStyles = (span: Span) => css`
  ${flexStyle(span)};
`;

export const StyledCell = styled.div<{ span?: Span }>`
  box-sizing: border-box;
  padding: 1rem;
  max-width: 100%;
  flex: 0 0 auto;
  padding: 1rem;
  ${({ span }) => (span && typeof span !== 'object' ? writeStyles(span) : '')};
`;
