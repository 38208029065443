import styled from 'styled-components';

export const Input = styled.input`
  height: 0;
  overflow: hidden;
  width: 0;
`;

export const Label = styled.label`
  background: #84bdab;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: inherit;
  font-weight: 600;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 3rem;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
  }
`;
