import styled from 'styled-components';

export const ImageContainer = styled.div`
  max-width: 26rem;
  margin: 0 auto 2rem;
  border: 2px solid white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem;
`;

export const Image = styled.img`
  width: 100%;
`;

export const MetaDataBlocks = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  margin: 2rem 0;
`;

export const InfoBlock = styled.div`
  border: 1px solid #c4dcbc;
  border-radius: 5px;
  padding: 1rem;
  color: #84bdab;
`;

export const LegalText = styled.p`
  font-size: 0.875rem;
`;

export const VideoWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const NoVideo = styled.div`
  max-width: 100%;
  height: 10rem;
  background: #e1ebf1;
  margin: 2rem auto;
  color: #8aa5b6;
  border: 1rem solid white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Hide = styled.div`
  display: none;
`;
