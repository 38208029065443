import { ContentActions } from '../actions/content';
import { ActionTypes } from '../actions/actionTypes';
import { api } from '../utils/api';
import { logger } from '../utils/logger';
import { ContentPageDto } from '../types/content';

export const fetchContent = async (dispatch: React.Dispatch<ContentActions>, slug: string) => {
  dispatch({ type: ActionTypes.FETCH_CONTENT });
  const response = await api({
    path: `pages?filters[slug][$eq]=${slug}`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error || (response.data && response.data.length <= 0)) {
    dispatch({ type: ActionTypes.FETCH_CONTENT_ERROR });
    logger.error(`Page with slug '${slug}' not found`);
  } else {
    const formattedResponse = response.data.data.map((page: ContentPageDto) => ({
      id: page.id,
      ...page.attributes
    }));
    dispatch({
      type: ActionTypes.FETCH_CONTENT_SUCCESS,
      payload: formattedResponse
    });
  }
};
