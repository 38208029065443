import React, { Fragment, useState, useEffect, FC } from 'react';
import FileInput from '../FileInput';
import { FileName, FilePreview } from './styles';

interface Props {
  onImageSelect: (params: File) => void;
}
export const FileUpload: FC<Props> = ({ onImageSelect }) => {
  const [file, setFile] = useState<File>();
  const [imgSrc, setImgSrc] = useState<string>();
  const handleChange = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      setFile(fileList[0]);
    }
  };
  useEffect(() => {
    if (file) {
      onImageSelect(file);
      setImgSrc(URL.createObjectURL(file));
    }
  }, [file, onImageSelect]);
  return (
    <Fragment>
      <FileInput handleChange={handleChange} />
      {file && <FileName>{file.name}</FileName>}
      {imgSrc && <FilePreview src={imgSrc} alt="preview of your picture" />}
    </Fragment>
  );
};
