import React, { FC } from 'react';
import moment from 'moment';
import { Thumbnails, ThumbContainer } from './styles';
import { Checkbox } from '../../components/Checkbox';
import { ButtonContainer, DeleteButton, LargeButton } from '../../components/Button';
import { Project } from '../../types/project';
import { Photo, PhotoType } from '../../types/photo';
import { CheckboxContainer, Thumbnail, Meta, ThumbnailsContainer } from './styles';

interface Props {
  project: Project;
  onTogglePhoto: (projectId: string, photoId: string, type: PhotoType) => void;
  onCreateTimelapse: () => void;
  putBackPhotos: () => void;
  deletePhotos: () => void;
  isLoadingTimelapse: boolean;
  type: PhotoType;
  photos?: Photo[];
}

export const ProjectThumbnails: FC<Props> = ({
  project,
  onTogglePhoto: onToggle,
  onCreateTimelapse,
  putBackPhotos,
  deletePhotos,
  isLoadingTimelapse,
  type,
  photos
}) => {
  const amountOfPhotosForTimelapse = project.newPhotos
    ? project.newPhotos.filter((photo) => photo.isSelected).length
    : 0;
  const amountOfPhotosForTimelapseRejected = project.rejectedPhotos
    ? project.rejectedPhotos.filter((photo) => photo.isSelected).length
    : 0;
  return (
    <ThumbnailsContainer>
      <Thumbnails>
        {photos &&
          photos.map(
            (photo) =>
              photo.attributes.file && (
                <ThumbContainer key={photo.id} onClick={() => onToggle(project.id, photo.id, type)}>
                  <CheckboxContainer>
                    <Checkbox
                      value={photo.isSelected}
                      onChange={() => onToggle(project.id, photo.id, type)}
                    />
                  </CheckboxContainer>
                  <Thumbnail
                    enabled={photo.isSelected}
                    src={photo.attributes.file.data.attributes.formats?.thumbnail?.url}
                  />
                  <Meta enabled={photo.isSelected}>
                    {moment(photo.attributes.uploadedAt).format('DD.MM.YY HH:mm')}
                  </Meta>
                </ThumbContainer>
              )
          )}
      </Thumbnails>
      {type === 'NEW' && (
        <LargeButton
          onClick={onCreateTimelapse}
          disabled={isLoadingTimelapse || !photos || photos.length === 0}
        >
          {amountOfPhotosForTimelapse === 0
            ? `Wijzigingen opslaan`
            : `Maak timelapse (${amountOfPhotosForTimelapse} nieuwe foto${
                amountOfPhotosForTimelapse !== 1 ? `'s` : ``
              })`}
        </LargeButton>
      )}
      {type === 'TIMELAPSE' && (
        <LargeButton
          onClick={onCreateTimelapse}
          disabled={isLoadingTimelapse || !photos || photos.length === 0}
        >
          Maak nieuwe timelapse
        </LargeButton>
      )}
      {type === 'REJECTED' && (
        <ButtonContainer>
          <LargeButton
            onClick={putBackPhotos}
            disabled={
              isLoadingTimelapse ||
              !photos ||
              photos.length === 0 ||
              amountOfPhotosForTimelapseRejected === 0
            }
          >
            Zet terug ({amountOfPhotosForTimelapseRejected})
          </LargeButton>
          <DeleteButton
            onClick={deletePhotos}
            disabled={
              isLoadingTimelapse ||
              !photos ||
              photos.length === 0 ||
              amountOfPhotosForTimelapseRejected === 0
            }
          >
            Verwijder ({amountOfPhotosForTimelapseRejected})
          </DeleteButton>
        </ButtonContainer>
      )}
    </ThumbnailsContainer>
  );
};
