import React, { FC } from 'react';
import { ArrowDown } from './ArrowDown';
import { ArrowUp } from './ArrowUp';
import { CrossIcon } from './Cross';
import { Checkmark } from './Checkmark';
import { Element } from './Element';
import { MenuIcon } from './Menu';
import { MinusIcon } from './Minus';
import { PlusIcon } from './Plus';
import { SpinnerIcon } from './Spinner';
import { TrashRoundIcon } from './TrashRound';

const icons = {
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  checkmark: Checkmark,
  cross: CrossIcon,
  element: Element,
  menu: MenuIcon,
  minus: MinusIcon,
  plus: PlusIcon,
  spinner: SpinnerIcon,
  trashRound: TrashRoundIcon
};

export type IconName = keyof typeof icons;

export const Icon: FC<{ type: IconName }> = ({ type }) => {
  const Component = icons[type];
  return Component ? <Component height="100%" width="100%" /> : null;
};
