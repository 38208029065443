import { ProjectActions } from '../actions/project';
import { ActionTypes } from '../actions/actionTypes';
import { api } from '../utils/api';

export const storeEmailAddress = async (
  dispatch: React.Dispatch<ProjectActions>,
  emailAddress: string
) => {
  dispatch({ type: ActionTypes.STORE_EMAIL_ADDRESS });
  const response = await api({
    path: `natuurgekkie`,
    method: 'POST',
    body: {
      email: emailAddress
    }
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.STORE_EMAIL_ADDRESS_ERROR });
  } else {
    dispatch({ type: ActionTypes.STORE_EMAIL_ADDRESS_SUCCESS });
  }
};
