import styled from 'styled-components';
import { animated } from 'react-spring';
import { Link } from 'react-router-dom';

export const MenuWrapper = styled.div``;

export const MenuContainer = styled(animated.div)`
  z-index: 1001;
  position: fixed;
  background-color: white;
  right: 0;
  top: 0;
  bottom: 0;
  width: 25rem;
  @media (max-width: 576px) {
    width: 80vw;
  }
  box-shadow: -1px 0px 0px 0px rgba(138, 165, 182, 0.3);
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #8aa5b6;
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
`;

export const MenuItem = styled(Link)`
  color: #8aa5b6;
  text-decoration: none;
  flex: 1;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(138, 165, 182, 0.15);
  &:first-of-type {
    border-top: 1px solid rgba(138, 165, 182, 0.15);
  }
`;

export const PageCover = styled(animated.div)`
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(138, 165, 182, 0.5);
`;
