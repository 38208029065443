import React, { Fragment } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { logger } from './utils/logger';
import { routes, RouteProps } from './routes';
import { AppContainer, PageWrapper, GlobalStyle } from './styles';
import { Header } from './components/Header';
import { isAuthenticated } from './utils/authenticate';

const RouteWithSubRoutes = (route: RouteProps & { component?: any }) => {
  const [cookies] = useCookies();
  const isAuth = cookies.jwt_auth && isAuthenticated(cookies.jwt_auth);
  if (route.private && !isAuth && route.location) {
    logger.info(
      `Not authenticated for ${route.location.pathname}. Redirected to /login`
    );
    return (
      <Redirect to={`/login/r=${route.location && route.location?.pathname}`} />
    );
  } else {
    return (
      <Route
        path={route.path}
        render={props => {
          if (route.component) {
            if (route.location)
              logger.info(`Navigated to ${route.location.pathname}`);
            return (
              <route.component
                {...props}
                slug={route.slug}
                routes={route.routes}
              />
            );
          } else {
            return null;
          }
        }}
      />
    );
  }
};

export const Main = () => (
  <CookiesProvider>
    <Router>
      <Fragment>
        <GlobalStyle />
        <AppContainer>
          <PageWrapper>
            <Header />
            <Switch>
              {routes.map((route, idx) => (
                <RouteWithSubRoutes key={idx} {...route} />
              ))}
            </Switch>
          </PageWrapper>
        </AppContainer>
      </Fragment>
    </Router>
  </CookiesProvider>
);
