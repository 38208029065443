import { useEffect, useState } from 'react';
import { ProjectsState } from '../../reducers/projects';
import { Project } from '../../types/project';

export const useRandomTimelapse = (projects: ProjectsState) => {
  const [randomProject, setRandomProject] = useState<Project>();
  useEffect(() => {
    if (projects.projects) {
      const projectsWithTimelapse = projects.projects.filter(
        (project) => project?.attributes?.enabled && project?.attributes?.timelapseUrl
      );
      if (projectsWithTimelapse.length) {
        setRandomProject(
          projectsWithTimelapse[Math.floor(Math.random() * projectsWithTimelapse.length)]
        );
      }
    }
  }, [projects]);
  return randomProject
    ? { url: randomProject.attributes.timelapseUrl, title: randomProject.attributes.name }
    : {};
};
