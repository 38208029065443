import styled from 'styled-components';

export const HeaderContainer = styled.header`
  height: 2.5rem;
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled.div`
  flex: 1;
  height: 100%;
`;

export const LogoImage = styled.img`
  height: 100%;
`;

export const MenuIcon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  color: #8AA5B6;
  position: absolute;
  right: 1rem;
`;