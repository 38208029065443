import { RouteProps as RRProps } from 'react-router';
import { Project } from './pages/Project';
import { Projects } from './pages/Projects';
import { ContentPage } from './pages/Content';
import { Admin } from './pages/Admin';
import { Login } from './pages/Login';

export interface RouteProps extends RRProps {
  slug?: string;
  private?: boolean;
  routes?: ReadonlyArray<RouteProps>;
}
export const routes: ReadonlyArray<RouteProps> = [
  {
    path: '/',
    exact: true,
    component: ContentPage,
    slug: 'about'
  },
  {
    path: '/privacy',
    exact: true,
    component: ContentPage,
    slug: 'privacy'
  },
  {
    path: '/projects',
    exact: true,
    component: Projects,
    slug: 'projects'
  },
  {
    path: '/contact',
    exact: true,
    component: ContentPage,
    slug: 'contact'
  },
  {
    path: '/admin',
    private: true,
    exact: true,
    component: Admin
  },
  {
    path: '/login',
    exact: true,
    component: Login
  },
  {
    path: '/login/r=/:r',
    exact: true,
    component: Login
  },
  {
    path: '/:projectSlug',
    exact: true,
    component: Project,
    slug: 'project'
  }
];
