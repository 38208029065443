import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

export const TabList = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 1rem 0;
`;

export const Tab = styled.a<{ selected?: boolean }>`
  color: ${({ selected }) => (selected ? '#84BDAB' : 'inherit')};
  font-weight: ${({ selected }) => (selected ? '700' : '400')};
  border-bottom: ${({ selected }) =>
    `2px solid ${selected ? '#84BDAB' : 'transparent'}`};
  padding-bottom: 0.5rem;
  margin: 0 1rem;
  &:first-of-type {
    margin-left: 0;
  }
  cursor: pointer;
  position: relative;
  top: 3px;
  transition: all 0.3s;
`;

export const TabPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;
