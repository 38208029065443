import styled from 'styled-components';

export const Block = styled.section`
  padding: 1rem;
  flex: 1;
  justify-content: middle;
`;

export const ImageContainer = styled.div`
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
`;

export const Image = styled.img`
  max-width: 100%;
`;
