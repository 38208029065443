export enum ActionTypes {
  FETCH_CONTENT = 'fetch_content',
  FETCH_CONTENT_ERROR = 'fetch_content_error',
  FETCH_CONTENT_SUCCESS = 'fetch_content_success',
  FETCH_PROJECT = 'fetch_project',
  FETCH_PROJECT_SUCCESS = 'fetch_project_success',
  FETCH_PROJECT_ERROR = 'fetch_project_error',
  FETCH_PROJECT_NOT_FOUND = 'fetch_project_not_found',
  UPDATE_PROJECT = 'update_project',
  UPDATE_PROJECT_ERROR = 'update_project_error',
  UPDATE_PROJECT_SUCCESS = 'update_project_success',
  SELECT_IMAGE = 'select_image',
  CLEAR_IMAGE = 'clear_image',
  UPLOAD_IMAGE = 'upload_image',
  UPLOAD_IMAGE_ERROR = 'upload_image_error',
  UPLOAD_IMAGE_SUCCESS = 'upload_image_success',
  STORE_IMAGE = 'store_image',
  STORE_IMAGE_ERROR = 'store_image_error',
  STORE_IMAGE_SUCCESS = 'store_image_success',
  STORE_EMAIL_ADDRESS = 'store_email_address',
  STORE_EMAIL_ADDRESS_ERROR = 'store_email_address_error',
  STORE_EMAIL_ADDRESS_SUCCESS = 'store_email_address_success',
  FETCH_PROJECTS = 'fetch_projects',
  FETCH_PROJECTS_ERROR = 'fetch_projects_error',
  FETCH_PROJECTS_SUCCESS = 'fetch_projects_success',
  LOGIN = 'login',
  LOGIN_ERROR = 'login_error',
  LOGIN_SUCCESS = 'login_success',
  CREATE_TIMELAPSE = 'create_timelapse',
  CREATE_TIMELAPSE_ERROR = 'create_timelapse_error',
  CREATE_TIMELAPSE_SUCCESS = 'create_timelapse_success',
  FETCH_NEW_PHOTOS_FOR_PROJECT = 'fetch_new_photos_for_project',
  FETCH_NEW_PHOTOS_FOR_PROJECT_ERROR = 'fetch_new_photos_for_project_error',
  FETCH_NEW_PHOTOS_FOR_PROJECT_SUCCESS = 'fetch_new_photos_for_project_success',
  FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT = 'fetch_timelapse_photos_for_project',
  FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT_ERROR = 'fetch_timelapse_photos_for_project_error',
  FETCH_TIMELAPSE_PHOTOS_FOR_PROJECT_SUCCESS = 'fetch_timelapse_photos_for_project_success',
  FETCH_REJECTED_PHOTOS_FOR_PROJECT = 'fetch_rejected_photos_for_project',
  FETCH_REJECTED_PHOTOS_FOR_PROJECT_ERROR = 'fetch_rejected_photos_for_project_error',
  FETCH_REJECTED_PHOTOS_FOR_PROJECT_SUCCESS = 'fetch_rejected_photos_for_project_success',
  TOGGLE_SELECTED_PHOTO = 'toggle_selected_photo',
  DELETE_PHOTO = 'delete_photo',
  DELETE_PHOTO_SUCCESS = 'delete_photo_success',
  DELETE_PHOTO_ERROR = 'delete_photo_error',
  FETCH_ALL_PHOTOS = 'fetch_all_photos',
  FETCH_ALL_PHOTOS_ERROR = 'fetch_all_photos_error',
  FETCH_ALL_PHOTOS_SUCCESS = 'fetch_all_photos_success',
  FETCH_TIMELAPSE_PHOTOS = 'fetch_timelapse_photos',
  FETCH_TIMELAPSE_PHOTOS_ERROR = 'fetch_timelapse_photos_error',
  FETCH_TIMELAPSE_PHOTOS_SUCCESS = 'fetch_timelapse_photos_success',
  TIMELAPSE_PHOTOS_READY_FOR_PROJECT = 'timelapse_photos_ready_for_project',
  FETCH_ASSEMBLY_STATUS_SUCCESS = 'retreive_assembly_status_success',
  CREATE_TIMELAPSE_READY = 'create_timelapse_ready',
  BULK_UPDATE_PHOTOS = 'bulk_update_photos',
  BULK_UPDATE_PHOTOS_ERROR = 'bulk_update_photos_error',
  BULK_UPDATE_PHOTOS_SUCCESS = 'bulk_update_photos_success'
}
