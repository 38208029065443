import React from 'react';

export const ArrowUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 16" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-992.000000, -307.000000)" fill="currentColor">
        <g transform="translate(1004.000000, 315.000000) rotate(180.000000) translate(-1004.000000, -315.000000) translate(992.000000, 307.000000)">
          <polygon points="23.245 0 12 14.374 0.781 1.77635684e-15 2.33146835e-15 0.619 12 16 24 0.609" />
        </g>
      </g>
    </g>
  </svg>
);
