import React, { FC } from 'react';
import { Input, Label } from './styles';

interface Props {
  handleChange: (fileList: FileList | null) => void;
}

const FileInput: FC<Props> = ({ handleChange }) => (
  <React.Fragment>
    <Input
      type="file"
      id="file"
      capture="camera"
      onChange={e => handleChange(e.currentTarget.files)}
    />
    <Label htmlFor="file">Voeg foto toe</Label>
  </React.Fragment>
);

export default FileInput;
