import { logger } from '../utils/logger';
import { api } from '../utils/api';

export const notifySlack = async (project: string, imageUrl: string) => {
  const formData = new FormData();
  formData.append('project', project);
  const response = await api({
    path: `functions/new_photo`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      project,
      imageUrl
    }
  });
  if (response.status !== 200 || response.error) {
    logger.info(`Something went wrong while posting to Slack`);
  }
};
