import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #404040;
  }

  p {
    line-height: 1.5rem;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`;

export const AppContainer = styled.div`
  background-color: white;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
`;

export const PageWrapper = styled.div`
  padding: 1rem;
  text-align: center;
  max-width: 32rem;
  margin: 0 auto;
`;
