import { api } from '../utils/api';
import { logger } from '../utils/logger';
import { ProjectActions } from '../actions/project';
import { ActionTypes } from '../actions/actionTypes';
import { ProjectsActions } from '../actions/projects';

const PROJECTS_PATH = 'projects';

export const fetchProject = async (
  dispatch: React.Dispatch<ProjectActions>,
  projectSlug: string
) => {
  dispatch({ type: ActionTypes.FETCH_PROJECT });
  const response = await api({
    path: `${PROJECTS_PATH}?filters[slug][$eq]=${projectSlug}&populate=owner`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.FETCH_PROJECT_ERROR });
  } else if (response.data && response.data.length === 0) {
    logger.error(`Project with slug '${projectSlug}' not found`);
    dispatch({ type: ActionTypes.FETCH_PROJECT_NOT_FOUND });
  } else {
    const formattedResponse = response.data.data.map((project: any) => ({
      ...project
    }));

    dispatch({
      type: ActionTypes.FETCH_PROJECT_SUCCESS,
      payload: formattedResponse
    });
  }
};

export const fetchAllProjects = async (dispatch: React.Dispatch<ProjectsActions>) => {
  dispatch({ type: ActionTypes.FETCH_PROJECTS });
  const response = await api({
    path: `${PROJECTS_PATH}?sort=name`,
    method: 'GET'
  });
  if (response.status !== 200 || response.error || (response.data && response.data.length <= 0)) {
    dispatch({ type: ActionTypes.FETCH_PROJECTS_ERROR });
    logger.error(`No projects found`);
  } else {
    const formattedResponse = response.data.data.map((project: any) => ({
      ...project
    }));
    dispatch({
      type: ActionTypes.FETCH_PROJECTS_SUCCESS,
      payload: formattedResponse
    });
  }
};

export const updateProject = async (
  dispatch: React.Dispatch<ProjectsActions>,
  id: string,
  timelapseUrl: string
) => {
  dispatch({ type: ActionTypes.UPDATE_PROJECT });
  const response = await api({
    path: `${PROJECTS_PATH}/${id}`,
    method: 'PUT',
    body: { data: { timelapseUrl } }
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.UPDATE_PROJECT_ERROR });
  } else {
    dispatch({ type: ActionTypes.UPDATE_PROJECT_SUCCESS });
  }
};
