import { ProjectActions } from '../actions/project';
import { Project } from '../types/project';
import { ActionTypes } from '../actions/actionTypes';
import { api } from '../utils/api';
import { logger } from '../utils/logger';
import { notifySlack } from './functions';
import { DateTime } from 'luxon';
// import { IFTThook } from '../utils/iftt';

export const uploadImage = async (
  dispatch: React.Dispatch<ProjectActions>,
  selectedImage: File,
  project?: Project
) => {
  dispatch({ type: ActionTypes.UPLOAD_IMAGE });
  const uploadedAt = DateTime.local();
  const fileName = `${project ? project.attributes.slug : 'unknown-project'}-${uploadedAt.toFormat(
    'yyyy-MM-dd-HH-mm-ss'
  )}`;
  const formData = new FormData();
  formData.append('path', project ? `${project.attributes.slug}` : 'unknown-project');
  formData.append('files', selectedImage, fileName);
  const response = await api({
    path: `upload`,
    method: 'POST',
    formData: formData
  });
  if (response.status !== 200 || response.error) {
    dispatch({ type: ActionTypes.UPLOAD_IMAGE_ERROR });
    logger.error(
      `Uploading new photo for project "${project && project.attributes.name}" failed! Error: ${
        response.status
      }, ${response.error}`
    );
  } else {
    dispatch({
      type: ActionTypes.UPLOAD_IMAGE_SUCCESS,
      payload: { imageUrl: response.data[0].url, fileName, id: response.data[0].id }
    });
    logger.info(
      `Photo with ID "${response.data[0].id}" for project "${
        project && project.attributes.name
      }" successfully uploaded to ${response.data[0].url}`
    );
    notifySlack(project ? project.attributes.slug : 'unknown-project', response.data[0].url);

    // temp disabled
    // IFTThook({
    //   project: project ? project.attributes.slug : 'unknown-project',
    //   url: response.data[0].url
    // });
  }
};
