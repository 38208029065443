import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ProjectLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -1rem;
`;

export const ProjectLink = styled(Link)`
  flex: 1;
  display: flex;
  padding: 1rem;
  text-decoration: none;
  color: #4a4965;
  text-align: left;
  border-bottom: 1px solid rgba(132, 189, 171, 0.4);
  &:hover {
    background-color: rgba(132, 189, 171, 0.1);
  }
  &:first-of-type {
    border-top: 1px solid rgba(132, 189, 171, 0.4);
  }
`;

export const LogoElement = styled.div`
  height: 1rem;
  flex: 0 0 1rem;
  margin-right: 1rem;
`;

export const Info = styled.div`
  flex: 1;
  width: 100%;
`;

export const Name = styled.div`
  font-weight: bold;
`;

export const ShortDescription = styled.div`
  width: calc(100% - 2rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
